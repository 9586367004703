import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/layout';

// markup
const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle={ data.site.siteMetadata.title }>
    </Layout>
  )
}


export const query = graphql`
query {
  site {
    siteMetadata {
      title
      description
    }
  }
}
`

export default IndexPage
